.color-container {
  display: inline-block;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: -240px;
  width: 240px;
  font-size: var(--side-font, 0.875rem);
  transform: translateY(-60%);
  background-color: var(--color-white);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 999;
  transition: left 0.3s ease;
}

.color-container.show {
  left: 0;
  transition: left 0.3s ease;
}

.color-container .custom-span {
  height: 15px;
  cursor: pointer;
  width: 15px;
  padding: 16px;
  top: 0;
  right: -32px;
  background-color: #fff;
  position: absolute;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.color-container p {
  padding: 10px;
  font-weight: 700;
  text-transform: capitalize;
}

.color-list-style {
  list-style: none;
  margin: 20px;
  display: inline-flex;
}

.color-list-li {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 5px;
  cursor: pointer;
  padding: 15px;
}

ul.color-list-default li:first-child {
  background-color: #2ecc71;
}
