.service-item {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
}
.service-item::after,
.service-item::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  z-index: -1;
  opacity: 0.6;
  background-repeat: no-repeat;
  background-size: 20%;
}
.service-item::before {
  background-image: url("../../images/back.png");
  top: -4%;
  left: 4%;
  background-position: top right;
}
.service-item::after {
  background-image: url("../../images/back2.png");
  right: -15px;
  top: 8%;
  background-size: 15%;
  background-position: bottom left;
}
@media (min-width: 992px) {
  .service-item::after {
    background-size: 15%;
    top: 10%;
  }
}
section.services__holder > article:nth-child(1) > .service-item {
  border-top: 4px solid #3a47d5;
}
section.services__holder > article:nth-child(2) > .service-item {
  border-top: 4px solid #f50057;
}
section.services__holder > article:nth-child(3) > .service-item {
  border-top: 4px solid #00b8d4;
}
section.services__holder > article:nth-child(4) > .service-item {
  border-top: 4px solid #1a237e;
}

.service-item > figure img {
  height: 120px;
  width: 120px;
  max-height: 120px;
  margin-right: auto;
}
