.skill__name {
  font-size: 1.5rem;
}

.skills__container {
  color: white;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.5s ease;
}
.skills__container:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.skills__container::before {
  background: linear-gradient(135deg, #00d2ff 25%, #3a47d5 75%);
  /* background: linear-gradient(135deg, #21d4fd 25%, #b721ff 75%); */
  transition: all 0.5s ease;
  transform: translate(-25%, -25%);
  content: "";
  position: absolute;
  display: block;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
  z-index: -1;
}
.skills__container:hover::before {
  transform: translate(-25%, -25%) rotate(-180deg);
}
.skills__container > p {
  color: var(--text-color);
  background: white;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  z-index: -1;
}
.skills__container > p::after,
.skills__container > p::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
  z-index: -1;
  opacity: 0.6;
  background-repeat: no-repeat;
  background-size: 35%;
}

.skills__container > p::before {
  background-image: url("../../images/back3.png");
  right: 20%;
  top: 16%;
  background-size: 80%;

  background-position: bottom left;
}
.skills__container > p::after {
  background-image: url("../../images/back2.png");
  left: 16%;
  top: -13%;
  background-position: top right;
}
@media (min-width: 768px) {
  .skills__container > p {
    font-size: 1.2rem;
  }
}
