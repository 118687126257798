a.footer__icon {
  color: var(--color-white);
  font-size: 24px;
  border: 1px solid var(--border-color);
  height: 58px;
  width: 58px;
  top: 0;
  transition: background-color 0.3s ease, color 0.3s ease, top 0.3s ease;
}

a.footer__icon:hover {
  top: -5px;
  color: var(--submit-footer-icon-background, #191e22);
  background-color: var(--section-background-color, #f2f2f2);
}
.header__social a.footer__icon {
  overflow: hidden;
  z-index: 1;
}
.header__social a.footer__icon::before {
  background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  position: absolute;
}

.header__social a.footer__icon:hover {
  color: var(--color-white);
  border: 1px solid #00d2ff;
}
.header__social a.footer__icon:hover::before {
  opacity: 1;
}
