.portfolio figure {
  z-index: 1;
}

.portfolio .card__image {
  height: inherit;
}
.portfolio .card__container figure::after,
.portfolio .card__container figure::before {
  position: absolute;
  background-size: cover;
  background-position: right;
  background-image: url("../../images/circle.png");
  transform: translate(-50%, -50%) rotate(180deg);
  filter: opacity(0.9);
  z-index: -1;
  content: "";
}
.portfolio .card__container figure::before {
  height: 150px;
  width: 150px;
  left: 80%;
  top: 30px;
}
.portfolio .card__container figure::after {
  height: 85px;
  width: 85px;
  left: 3%;
  top: 50%;
  filter: drop-shadow(2px 2px 6px #00d2ff) opacity(0.8);
}

.portfolio .card-item h3 {
  color: var(--main-color);
}

@media (max-width: 767px) {
  .lead {
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .portfolio .card-item h3 {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .portfolio .card-item h3 {
    font-size: 1.2rem;
  }
  .portfolio .card-item p {
    font-size: 18px;
  }
}

.card-item .card__container {
  position: relative;
}
.card-item .card__container img {
  transition: all 0.4s ease;
}
.card-item .card__container:hover img {
  transform: scale(1.05);
}
.card-item .card__container::before {
  background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  /* opacity: 0.7; */
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
}

.card-item .card__detail--holder .details__wrapper {
  display: grid;
  grid-template-areas:
    "card_detail"
    "card_detail"
    "card_detail"
    "links";
}

.card__detail {
  color: var(--text-color);
  grid-area: card_detail;
}

.card__detail--holder {
  overflow: hidden;
}

@media (min-width: 992px) {
  .card__detail--holder::before,
  .card__detail--holder::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-size: 45%;
    opacity: 0.6;
    background-repeat: no-repeat;
  }
  .card__detail--holder::before {
    background-image: url("../../images/back3.png");
    top: -16px;
    left: 69%;
  }
  .card__detail--holder::after {
    background-image: url("../../images/back.png");
    top: 80%;
    right: 3%;
  }
}

.link-holder {
  grid-area: links;
}

.portfolio .card-item .link-holder a {
  color: var(--main-color, #2ecc71);
  border: 0.1px solid var(--main-color);
  text-decoration: none;
  border-radius: 4px;
  font-weight: 700;
  float: right;
  font-size: 0.75rem;
  position: relative;
  overflow: hidden;
  text-transform: capitalize;
  z-index: 1;
}

.portfolio .card-item .link-holder a:not(:first-child) {
  margin-right: 1rem;
}

.portfolio .card-item .link-holder a:hover {
  color: white;
  font-weight: bold;
  border: 0.1px solid #00d2ff;
}

.portfolio .card-item .link-holder a::after {
  z-index: -1;
  position: absolute;
  top: -100%;
  right: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  transition: all 0.4s ease;
}

.portfolio .card-item .link-holder a:hover::after {
  top: 0;
  border: 1px solid #00d2ff;
}

.portfolio .card-item > div.card {
  border-radius: 0;
  border: none;
}
.card-img,
.card-img-top {
  border-radius: 0;
}

@media (min-width: 992px) {
  .portfolio .card-item:nth-child(odd) .card__container {
    order: 6;
  }
}
